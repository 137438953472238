<template>
  <v-avatar :size="size" :tile="tile" :class="avatarClass" icon>
    <v-img v-if="src" :src="src" :alt="alt" contain></v-img>
    <v-icon v-else class="avatar-placeholder">{{ placeholder }}</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: "BaseAvatar",
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      default: null,
    },
    avatarClass: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "30px",
    },
    alt: {
      type: String,
      default: "avatar",
    },
    tile: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "person",
    },
  },
}
</script>

<style scoped lang="scss">
div.v-avatar {
  margin-right: 6px;

  &.placeholder {
    background: rgba(0, 0, 0, 0.12);

    i.avatar-placeholder {
      color: white;
    }
  }

  img.rounded-tile {
    border-radius: 4px;
  }

  &.autocomplete-search-avatar {
    width: 24px;
    height: auto;

    &.person {
      border: 2px solid #c4c4c4;

      .v-icon {
        color: #dadada;
      }
    }
  }

  &.field-avatar {
    border-radius: 50%;
  }
}
</style>
