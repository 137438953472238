import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{class:[_vm.disabled ? 'disabled-base-menu' : '', 'base-menu'],attrs:{"disabled":_vm.disabled,"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c(VBtn,_vm._g({staticClass:"base-menu-button",class:_vm.menuClass,attrs:{"disabled":_vm.disabled,"icon":_vm.icon,"light":_vm.lightTheme,"dark":_vm.darkTheme,"color":_vm.btnColor,"width":_vm.menuButtonWidth,"height":_vm.menuButtonHeight},on:{"click":function($event){$event.stopPropagation();}}},Object.assign({}, menu)),[_vm._t("button-slot")],2)]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[(_vm.addingToContainer)?_c('AddToContainerList',{attrs:{"companies":_vm.companies},on:{"closeParentMenu":function($event){_vm.showMenu = false}}}):_c(VList,{staticClass:"menu-options-wrapper",attrs:{"subheader":_vm.subheader,"two-line":_vm.twoLine,"three-line":_vm.threeLine,"dense":""}},[(_vm.enableSubheader)?_c(VSubheader,[_vm._v(_vm._s(_vm.subheaderText))]):_vm._e(),_vm._l((_vm.options),function(option){return _c(VListItem,{key:option.title,on:{"click":function($event){$event.preventDefault();return _vm.callAction(option)}}},[_c(VListItemContent,[_c(VListItemTitle,[(option.icon)?_c(VIcon,{staticClass:"title-icon",domProps:{"textContent":_vm._s(option.icon)}}):_vm._e(),_vm._v(" "+_vm._s(option.title)+" ")],1),(option.subtitle)?_c(VListItemSubtitle,[_vm._v(_vm._s(option.subtitle))]):_vm._e()],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }