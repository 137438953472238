// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from "vue"
import upperFirst from "lodash/upperFirst"
import camelCase from "lodash/camelCase"

const requireComponent = require.context("./base", true, /_base[\w-]+\.vue$/)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentFileName = fileName.split("/").pop()
  const componentName = upperFirst(
    camelCase(componentFileName.replace(/^\.\/_/, "").replace(/\.\w+$/, ""))
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})
