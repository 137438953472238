<template>
  <div class="base-loader-container">
    <v-progress-circular
      v-if="loading"
      :size="size"
      :color="color"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "BaseLoader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 100,
    },
    color: {
      type: String,
      default: "#1C1836",
    },
  },
}
</script>

<style scoped lang="scss">
div.base-loader-container {
  margin: 20% auto;
  text-align: center;
}
</style>
