<template>
  <v-menu
    v-model="showMenu"
    :disabled="disabled"
    :class="[disabled ? 'disabled-base-menu' : '', 'base-menu']"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on: menu }">
      <v-btn
        v-on="{ ...menu }"
        :class="menuClass"
        :disabled="disabled"
        :icon="icon"
        :light="lightTheme"
        :dark="darkTheme"
        :color="btnColor"
        :width="menuButtonWidth"
        :height="menuButtonHeight"
        class="base-menu-button"
        @click.stop
      >
        <slot name="button-slot"></slot>
      </v-btn>
    </template>

    <AddToContainerList
      v-if="addingToContainer"
      :companies="companies"
      @closeParentMenu="showMenu = false"
    />

    <v-list
      v-else
      :subheader="subheader"
      :two-line="twoLine"
      :three-line="threeLine"
      dense
      class="menu-options-wrapper"
    >
      <v-subheader v-if="enableSubheader">{{ subheaderText }}</v-subheader>
      <v-list-item
        v-for="option in options"
        :key="option.title"
        @click.prevent="callAction(option)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon
              v-if="option.icon"
              class="title-icon"
              v-text="option.icon"
            ></v-icon>
            {{ option.title }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="option.subtitle">{{
            option.subtitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import DialogHelper from "@mixins/app/dialog-helper"
import Anchor from "@mixins/fields/anchor"
import AddToContainerList from "@components/companies/add-to-container-list"

export default {
  name: "BaseMenu",
  inheritAttrs: false,
  components: {
    AddToContainerList,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    menuClass: {
      type: String,
      default: null,
    },
    menuButtonHeight: {
      type: Number,
      default: 40,
    },
    menuButtonWidth: {
      type: Number,
      default: 40,
    },
    subheader: {
      type: Boolean,
      default: false,
    },
    twoLine: {
      type: Boolean,
      default: false,
    },
    threeLine: {
      type: Boolean,
      default: false,
    },
    enableSubheader: {
      type: Boolean,
      default: false,
    },
    subheaderText: {
      type: String,
      default: "Save filter to...",
    },
    lightTheme: {
      type: Boolean,
      default: true,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    btnColor: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showMenu: false,
      addingToContainer: false,
      companies: [],
    }
  },
  methods: {
    callAction(option) {
      this[option.action](option)
      if (!["addCompaniesToContainer"].includes(option.action)) {
        this.showMenu = false
      }
    },
    signoutAndCleanup() {
      return this.$store.dispatch("auth/signOut").catch((error) => {
        this.error = error
      })
    },
    toggleDialog(option) {
      DialogHelper.toggle(this.$store, option)
    },
    transitionTo(option) {
      Anchor.open(this.$router, option)
    },
    emitAction(option) {
      this.$emit(option.emitAction, option.title, option.object)
    },
    dispatchAction(option) {
      this.$store.dispatch(option.actionNamespace, option.actionPayload)
    },
    addCompaniesToContainer(option) {
      this.companies = option.companies
      this.addingToContainer = true
    },
  },
  watch: {
    showMenu() {
      if (!this.showMenu) {
        setTimeout(() => {
          this.addingToContainer = false
        }, 300)
      }
    },
  },
}
</script>

<style lang="scss">
div.v-menu.base-menu {
  &.disabled-base-menu {
    div.v-menu__activator--disabled {
      cursor: unset;
    }
  }
}

button.base-menu-button {
  &.no-flex {
    flex: none !important;
  }

  &.toolbar-user-panel-options {
    margin-right: unset !important;
  }

  &.post-edit-options {
    position: absolute;
    top: 16px;
    right: 24px;
  }

  &.field-options-menu {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    margin: 2px;
    font-size: 18px;
  }

  &.report-options-menu {
    top: 6px;
    font-size: 20px;
  }
}

.title-icon {
  margin-right: 8px;
}

div.menu-options-wrapper {
  max-width: 250px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14);
}
</style>
